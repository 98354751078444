import React, {useEffect, useRef, useState} from 'react'
import Header from "../../Fragments/Header";
import {
    PayPalScriptProvider,
    PayPalHostedFieldsProvider, PayPalHostedField, usePayPalHostedFields
} from "@paypal/react-paypal-js";
import axios from "axios";
import api from "../../../api";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Spinner} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import PaypalButton from "../../../../src/icons/paypal.svg"
import Footer from "../../Fragments/Footer";


const CUSTOM_FIELD_STYLE = {
    "fontSize":"1rem",
    "border":'1px solid #ced4da',
    "borderRadius":'.25rem',
    padding: "0.375rem 0.75rem",
    outline:'none',
    height:'calc(1.5em + .75rem + 15px)'};

const SubmitPayment = ({ customStyle }) => {
    const [paying, setPaying] = useState(false);
    const cardHolderName = useRef(null);
    const hostedField = usePayPalHostedFields();
    const token = useSelector(state=>state.auth?.access_token)
    const state = useSelector((state)=>state)
    const history = useHistory()

    const handleClick = () => {
        if (!hostedField?.cardFields) {
            const childErrorMessage = 'Unable to find any child components in the <PayPalHostedFieldsProvider />';

            throw new Error(childErrorMessage);
        }
        const isFormInvalid =
            Object.values(hostedField.cardFields.getState().fields).some(
                (field) => !field.isValid
            ) || !cardHolderName?.current?.value;

        if (isFormInvalid) {
            return alert(
                "The payment form is invalid"
            );
        }
        setPaying(true);
        hostedField.cardFields
            .submit({
                cardholderName: cardHolderName?.current?.value,
            })
            .then((data) => {
                console.log({...data},data.orderId)
                axios.request( {
                    url:api.PayPal.capture.url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':token
                    },
                    method: api.PayPal.capture.method,
                    data:{
                        UserId: state.auth.user_id,
                        ShoppingCartId: state.cart.shoppingCartId,
                        ShoppingCartItemIds: state.cart.selectedItems,
                        orderId:data.orderId,
                    }
                }).then(response=>{
                    history.push('/Shop/Thanks/' + data.orderId + '/payed')
                }).finally(()=>{
                    setPaying(false)
                })

            })
            .catch((err) => {
                // Here handle error
                setPaying(false);
            });
    };

    return (
        <>
            <div>
         <div style={{marginTop:10}}>
             <input
                 id="card-holder"
                 ref={cardHolderName}
                 className="card-field"
                 style={{ ...customStyle, width: "100%" }}
                 type="text"
                 placeholder="Full name"
             />
         </div>

            </div>
            <button
                className={`profile-update-btn`}
                style={{ float: "right",marginTop:10, height:60, fontWeight:600, fontSize:20 }}
                onClick={handleClick}
            >
                {paying ?   <Spinner animation="border"/> : "PLACE ORDER"}
            </button>
        </>
    );
};

function Checkout(){
    const [paypalToken,setPaypalToken] = useState(null);
    const [buttonLoading,setButtonLoading] = useState(false);
    const [loading,setLoading] = useState(true);

    const state = useSelector((state)=>state)
    const token = useSelector(state=>state.auth?.access_token)
    const history = useHistory()
    const dispatch = useDispatch()
    const handleCheckoutClick =  ()=>{

        const data = JSON.stringify({
            UserId: state.auth.user_id,
            ShoppingCartId: state.cart.shoppingCartId,
            ShoppingCartItemIds: state.cart.selectedItems

        });
        setButtonLoading(true)
        axios.request({
            url: api.Cart.doCheckout.url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            method: api.Cart.doCheckout.method,
            data: data,
        }).then(response => response.data).then(response => {
            if (response.isPaymentNeeded) {
                document.location.href = response.url
            } else {
                history.push('/Shop/Thanks/' + data.orderId + '/payed')

            }
            setButtonLoading(false)
        }).catch(err => {
            dispatch({
                type: "NOTIFICATE",
                payload: {
                    type:'primary',
                    message:'Something went wrong'
                }
            })
        })
    }
    useEffect(()=>{
        axios.request( {
            url:api.PayPal.token.url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':token
            },
            method: api.PayPal.token.method,
        }).then(response=>{

            setPaypalToken(response?.data?.token)
            setLoading(false)
        })

    },[token])
    return  loading?<div>loading</div>:<div className={"cart-container"}>
                 <Header  title={'Checkout'}/>
        <Row className={'cart-data-container mt-5 mb-5'}>
            <Col lg={{span:4,offset:4}}>
                <h3 >Payment Method</h3>
                <PayPalScriptProvider
                    options={{
                        "client-id": process.env.REACT_APP_PAYPAL_CLIENT,
                        components: "buttons,hosted-fields",
                        "data-client-token": paypalToken,
                        intent: "capture",
                        vault: false,
                    }}
                >
                    <PayPalHostedFieldsProvider
                        styles={
                            {".valid":{"color":"#28a745"},
                                ".invalid":{"color":"#dc3545"},
                                "input":{
                                    "font-family":"monospace",

                                }
                            }}
                        createOrder={function () {
                            const data = JSON.stringify({
                                UserId: state.auth.user_id,
                                ShoppingCartId: state.cart.shoppingCartId,
                                ShoppingCartItemIds: state.cart.selectedItems

                            })
                            return  axios.request( {
                                url:api.PayPal.order.url,
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization':token
                                },
                                method: api.PayPal.order.method,
                                data:data
                            }).then(response=>{

                                return response.data.id;
                            })
                        }}
                    >
                        <button onClick={handleCheckoutClick} style={{width:'100%', marginTop:10, padding:20, backgroundColor:'#fabe32', border:'none', borderRadius:4,color:'#fff'}}>
                            {buttonLoading ? <Spinner animation="border"/> :<img alt={'paypal'} src={PaypalButton}/>}</button>
                        <div style={{marginTop:10, width:'100%', display:"flex", flexDirection:"row", justifyContent:'center'}}>
                            <div style={{width:"20%", margin:11}}><div style={{borderBottom:"1px solid #adadaa", }}></div></div>
                            <div>Or</div>
                            <div style={{width:"20%", margin:11}}><div style={{borderBottom:"1px solid #adadaa", }}></div></div>
                        </div>
                        <div style={{marginTop:10}}>
                            <PayPalHostedField
                                id="card-number"
                                className="card-field"
                                style={CUSTOM_FIELD_STYLE}
                                hostedFieldType="number"
                                options={{
                                    selector: "#card-number",
                                    placeholder: "Card Number",
                                }}
                            />
                        </div>
                        <div style={{display:"flex", width:'100%', gap:10, marginTop:10}}>
                            <div style={{width:'100%'}}>
                                <PayPalHostedField
                                    id="expiration-date"
                                    className="card-field"
                                    style={CUSTOM_FIELD_STYLE}
                                    hostedFieldType="expirationDate"
                                    options={{
                                        selector: "#expiration-date",
                                        placeholder: "Expiration",
                                    }}
                                />
                            </div>
                            <div style={{width:'100%'}}>
                                <PayPalHostedField
                                    id="cvv"
                                    className="card-field"
                                    style={CUSTOM_FIELD_STYLE}
                                    hostedFieldType="cvv"
                                    options={{
                                        selector: "#cvv",
                                        placeholder: "CVV",
                                        maskInput: true,
                                    }}
                                />
                            </div>
                        </div>






                        <SubmitPayment customStyle={CUSTOM_FIELD_STYLE} />
                    </PayPalHostedFieldsProvider>
                </PayPalScriptProvider>
            </Col>
        </Row>
        <Footer/>
    </div>
}
export default Checkout
